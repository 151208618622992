// sort out api server location based on build env var
const PRODUCTION_API_URL = 'https://api.greatnight.us/v1/api'
const STAGING_API_URL = 'https://api-dev.greatnight.us/v1/api'
const DEV_API_URL = 'http://localhost:3000/v1/api'

const buildEnv = process.env.REACT_APP_GREAT_NIGHT_ENV

const BASE_API_URL = buildEnv === "production"
  ? PRODUCTION_API_URL
  : buildEnv === "staging"
    ? STAGING_API_URL
    : DEV_API_URL

const config = {
  BASE_API_URL,
  GOOGLE_MAPS_API_KEY: 'AIzaSyBjIExfGrKmK6L4L8CuQEd05uY7MsaojRA',
  GOOGLE_AUTH_CLIENT_ID: '679642610493-bn3au4sacn0tpue7k5gmavt9tuas776p.apps.googleusercontent.com'
};

export default config;
