export const generateTimeOptions = () => {
  const options = [];
  // make HH:MM AM/PM options for every 30 minutes of the day
  for (let h = 0; h < 24; h++) {
    for (let m = 0; m < 60; m += 30) {
      const hour24 = h;
      const hour12 = (hour24 % 12 === 0 ? 12 : hour24 % 12).toString().padStart(2, '0');
      const minute = m.toString().padStart(2, '0');
      const period = hour24 < 12 ? 'AM' : 'PM';
      const time = `${hour12}:${minute} ${period}`;
      options.push(time);
    }
  }
  return options;
};