import React, { useEffect, useState } from 'react';
import './Login.css';
import { Link } from 'react-router-dom';
import Toasts from '../common/toasts/Toasts';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogleSignIn from './GoogleSignIn';
import config from './../../config/config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';

function Login({
  onSubmit,
  loading,
  formData,
  handleChange,
  handleInputBlur,
  errors,
  setShowToast,
  showToast,
  toastMessage,
  googleLogin
}) {
  const [user, setUser] = useState(null);
  // State for password visibility toggle
  const [showPassword, setShowPassword] = useState(false);

  // Handler for toggling password visibility
  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    if (user) {
      googleLogin(user)
    }
  }, [googleLogin, user])

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <div className="spinner-grow" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };


  return (
    <div className="container">
      <div id="login" className="row justify-content-center d-flex align-items-center">
        <div className="col-lg-6">
          <header>
            <div className="text-center mb-5">
              <img src="/assets/images/logo.svg" alt="GreatNight Logo" />
              <h2 id="tagline">Your Social Life Just Got Smarter</h2>
            </div>
          </header>
          <main>
            <Toasts show={showToast} message={toastMessage} onClose={() => setShowToast(false)} />
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <div className="input-group">
                  <label htmlFor="email"
                    className="form-label">
                    Email
                  </label>

                  <span className="input-group-text" id="basic-addon1">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </span>

                  <input type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                    placeholder="Enter email"
                    required />
                </div>
                <div className="form-text text-danger">
                  {errors?.email}
                </div>
              </div>
              <div className="mb-4">
                <div className="input-group">
                  <label htmlFor="cell"
                    className="form-label">
                    Password
                  </label>

                  <span className="input-group-text" id="basic-addon1">
                    <FontAwesomeIcon icon={faLock} />
                  </span>

                  <div style={inputContainerStyle}>
                    <input type={showPassword ? 'text' : 'password'}
                      className="form-control"
                      id="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      onBlur={handleInputBlur}
                      placeholder="Enter password"
                      required
                      style={inputStyle} />
                    <button type="button"
                      onClick={handleTogglePassword}
                      style={toggleButtonStyle}>
                      {showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                    </button>
                  </div>
                </div>
                <div className="form-text text-danger">
                  {errors?.password}
                </div>
              </div>
              <p className="mb-4 text-end">
                <Link to="/forgot-password" className="text-decoration-none inter-400" id="forgot-password-link">Forgot
                  the password?</Link>
              </p>
              {/*
              <div className="mb-3 form-check text-center">
                <label className="form-check-label"
                  htmlFor="remember-me">
                  <input type="checkbox"
                    className="form-check-input"
                    id="remember-me"
                    name="rememberMe"
                    checked={formData.rememberMe}
                    onChange={handleChange} /> Remember me
                </label>
              </div>
              */}
              <button type="submit"
                className="btn"
                id="continue"
                style={{ marginBottom: '15px' }}>
                Sign in
              </button>

              <div className="inter-700 text-center mb-4 mt-3 or">OR</div>

              <GoogleOAuthProvider clientId={config.GOOGLE_AUTH_CLIENT_ID}>
                <GoogleSignIn setUser={setUser} />
              </GoogleOAuthProvider>

            </form>
          </main>

          <footer id="footer">
            <p className="text-center mt-5">
              <span className="inter-400">Don't have an account? </span>
              <Link to="/register" className="text-decoration-none inter-700" id="sign-up-link">Sign up</Link>
            </p>
          </footer>
        </div>
      </div>
    </div>
  );
}

const inputContainerStyle = {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  width: 'calc(100% - 40px)',
};

const inputStyle = {
  paddingRight: '60px', // space for the toggle button
  width: '100%',
  boxSizing: 'border-box',
};

const toggleButtonStyle = {
  position: 'absolute',
  right: '10px',
  padding: '10px',
  fontSize: '16px',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
};

export default Login;
