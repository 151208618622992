import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './Profile.css';
import Toasts from '../common/toasts/Toasts';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

function Profile({
  loading,
  onSubmit,
  user,
  formData,
  handleChange,
  handleInputBlur,
  errors,
  setShowToast,
  showToast,
  toastMessage,
}) {
  const navigate = useNavigate();
  // Check if the current route starts with "/event-goer"
  const isFormValid = !Object.values(errors).some(error => error);


  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <div className="spinner-grow" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const logout = () => {
    localStorage.clear()
    Cookies.remove('gn_userToken')
    navigate('/')
  }

  return (
    <div id="profile-wrapper"
      className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <header>
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="mb-3 d-block text-black mt-3"
              style={{ cursor: 'pointer' }}
              onClick={() => navigate(-1)}
            />
          </header>
          <div className="container">
            <Toasts show={showToast} message={toastMessage} onClose={() => setShowToast(false)} />

            <section className="table-responsive pb-5">
              <h1 className="inter-700">Profile</h1>
              <div>
                <table className="table table-borderless align-middle">
                  <tbody>
                    {user && user.username && (<tr>
                      <th className="inter-500">Username</th>
                      <td className="inter-300">{user?.userName}</td>
                    </tr>)}
                    <tr>
                      <th className="inter-500">Email</th>
                      <td className="inter-300">{user?.email}</td>
                    </tr>
                    <tr>
                      <th className="inter-500">Role</th>
                      <td className="inter-300 text-capitalize">{user?.role}</td>
                    </tr>
                    <tr>
                      <th className="inter-500">Status</th>
                      <td className="inter-300">{user?.status}</td>
                    </tr>
                    <tr>
                      <th className="inter-500">Total Points</th>
                      <td className="inter-300">{user?.totalPoints}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>

            <section>
              <h2 className="inter-700">Change Password</h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <input type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                    placeholder="Email"
                    required
                    hidden />
                </div>
                <div className="mb-3">
                  <label htmlFor="otp"
                    className="form-label">
                    Current Password
                  </label>
                  <input type="password"
                    className="form-control"
                    id="currentPassword"
                    name="currentPassword"
                    value={formData.currentPassword}
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                    placeholder="Current Password"
                    required />
                  <div className="form-text text-danger">
                    {errors?.currentPassword}
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="password"
                    className="form-label">
                    Password
                  </label>
                  <input type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                    placeholder="New Password"
                    required />
                  <div className="form-text text-danger">
                    {errors?.password}
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="cell"
                    className="form-label">
                    Confirm Password
                  </label>
                  <input type="password"
                    className="form-control"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                    placeholder="Confirm Password"
                    required />
                  <div className="form-text text-danger">
                    {errors?.confirmPassword}
                  </div>
                </div>
                <button type="submit"
                  className="btn"
                  id="change-password"
                  style={{ marginBottom: '15px' }}
                  disabled={!isFormValid}>
                  Change Password
                </button>

                <p className="text-center">
                  <Link to="/forgot-password" className="text-decoration-none text-black">Forgot the password?</Link>
                </p>
              </form>
            </section>

            <div style={{ textAlign: 'center' }}>
              <button className="btn logout"
                type='submit'
                id="logout-button"
                style={{ marginBottom: '15px' }}
                onClick={logout}>
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;

