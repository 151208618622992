import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { getEventDetails, updateEvent, uploadImage } from '../services/eventService';
import EventEdit from '../components/event/edit/EventEdit';

function EventEditContainer() {
  const { eventId } = useParams();
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    category: '',
    type: '',
    tags: '',
    venueName: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    start: '',
    end: '',
    startDate: '',
    endDate: '',
    notes: '',
    model: '',
    ticketLink: ''
  });

  const navigate = useNavigate();

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedName, setSelectedName] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [address, setAddress] = useState(null);
  const [street, setStreet] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [zip, setZip] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [errors, setErrors] = useState({
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: ''
  });
  // eslint-disable-next-line no-unused-vars
  const [venueAddress, setVenueAddress] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await getEventDetails(eventId);
        if (response && response.data) {
          const event = response.data;
          console.log('event details:', event);
          setFormData({
            name: event.name || '',
            description: event.description || '',
            category: event.category || '',
            type: event.is_public,
            tags: event.tags || '',
            venueName: event.venue_name || '',
            street: event.street || '',
            city: event.city || '',
            state: event.state || '',
            zip: event.zip || '',
            country: event.country || 'USA',
            start: moment(event.start).format('hh:mm A'), // Updated to HH:mm AM format
            end: moment(event.end).format('hh:mm A'),
            startDate: moment(event.start).format('YYYY-MM-DD'),
            endDate: moment(event.end).format('YYYY-MM-DD'),
            notes: event.notes || '',
            model: event.model || 'woman',
            ticketLink: event.ticket_link || ''
          });
          setImageURL(event.flyer || '');
          setLoading(false);
        } else {
          throw new Error('Event data is missing');
        }
      } catch (error) {
        console.error('Error fetching event:', error);
        setMessage('Error fetching event.');
        setShowToast(true);
        setLoading(false);
      }
    };

    fetchEvent();
  }, [eventId]);


  const handleFileChange = async (file) => {
    console.log("Selected file:", file);

    setImageLoading(true);
    setDisableButton(true);
    setSelectedFile(file);
    setSelectedName(file.name);

    const formData = new FormData();
    formData.append('image', file);

    try {
      const url = await uploadImage(formData);
      console.log("Uploaded image URL:", url.data);
      setImageURL(url.data.imageUrl);
    } catch (error) {
      console.error("Error uploading the image:", error);
    } finally {
      setDisableButton(false);
      setImageLoading(false);
    }
  };

  useEffect(() => {
    const validateDateTime = () => {
      const { startDate, endDate, start, end } = formData;
      const format = 'YYYY-MM-DD hh:mm A';
      const timezone = 'America/New_York';

      let newErrors = {};

      if (startDate && endDate) {
        if (moment(startDate).isAfter(moment(endDate))) {
          newErrors.startDate = 'Start date cannot be later than end date';
          newErrors.endDate = 'End date cannot be earlier than start date';
        } else {
          newErrors.startDate = '';
          newErrors.endDate = '';

          if (start && end) {
            const startDateTime = moment.tz(`${startDate} ${start}`, format, timezone).utc();
            const endDateTime = moment.tz(`${endDate} ${end}`, format, timezone).utc();

            if (startDateTime.isAfter(endDateTime)) {
              newErrors.startTime = 'Start time cannot be later than end time';
              newErrors.endTime = 'End time cannot be earlier than start time';
            } else {
              newErrors.startTime = '';
              newErrors.endTime = '';
            }
          }
        }
      }

      setErrors(newErrors);
    };

    validateDateTime();
  }, [formData]);

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: name === 'type' ? Number(value) : value,
    }));

    console.log(name, value);

    if (name === 'state') {
      setState(value);
    }

    if (name === 'city') {
      setCity(value);
    }

    if (name === 'street') {
      setStreet(value);
    }

    if (name === 'zip') {
      setZip(value);
    }

    if (state !== null && city !== null && street !== null && zip !== null) {
      setAddress(`${street} ${city}, ${state} ${zip}`);
    }
  };

  const handleVenueSelected = (place) => {
    const address = place.formatted_address;
    setVenueAddress(address);

    const addressComponents = place.address_components.reduce((acc, component) => {
      if (component.types.includes('street_number')) {
        acc.street_number = component.long_name;
      } else if (component.types.includes('route')) {
        acc.route = component.long_name;
      } else if (component.types.includes('locality')) {
        acc.city = component.long_name;
      } else if (component.types.includes('administrative_area_level_1')) {
        acc.state = component.short_name;
      } else if (component.types.includes('postal_code')) {
        acc.postal_code = component.long_name;
      } else if (component.types.includes('country')) {
        acc.country = component.short_name;
      }
      return acc;
    }, {});

    formData.venueName = place.name || '';
    formData.street = addressComponents.street_number ? addressComponents.street_number + ' ' + addressComponents.route : '';
    formData.city = addressComponents.city || '';
    formData.state = addressComponents.state || '';
    formData.zip = addressComponents.postal_code || '';
    formData.country = addressComponents.country || '';
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    let newErrors = { ...errors };

    switch (name) {
      case 'name':
        newErrors.name = value.trim() === '' ? 'Event title is required' : '';
        break;
      case 'category':
        newErrors.category = value === '' ? 'Category is required' : '';
        break;
      case 'type':
        newErrors.type = value === '' ? 'Type is required' : '';
        break;
      case 'tags':
        newErrors.tags = value === '' ? 'Tag is required' : '';
        break;
      case 'street':
        newErrors.street = value.trim() === '' ? 'Street is required' : '';
        break;
      case 'city':
        newErrors.city = value.trim() === '' ? 'City is required' : '';
        break;
      case 'state':
        newErrors.state = value === '' ? 'State is required' : '';
        break;
      case 'zip':
        newErrors.zip = value.trim() === '' ? 'Postal code is required' : '';
        break;
      case 'startDate':
        newErrors.startDate = value.trim() === '' ? 'Start date is required' : '';
        break;
      case 'endDate':
        newErrors.endDate = value.trim() === '' ? 'End date is required' : '';
        break;
      case 'start':
        newErrors.startTime = value.trim() === '' ? 'Start time is required' : '';
        break;
      case 'end':
        newErrors.endTime = value.trim() === '' ? 'End time is required' : '';
        break;
      default:
        break;
    }

    setErrors(newErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    moment.tz.setDefault('America/New_York');
    setDisableButton(true)

    if (!imageURL) {
      setMessage('Image required.')
      setShowToast(true)
      setDisableButton(false)
      return
    }

    try {
      console.log('formData: ', formData);
      const startDateTime = moment.tz(
        `${formData.startDate} ${formData.start}`,
        'YYYY-MM-DD hh:mm A',
        'America/New_York'
      ).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');

      const endDateTime = moment.tz(
        `${formData.endDate} ${formData.end}`,
        'YYYY-MM-DD hh:mm A',
        'America/New_York'
      ).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');

      const eventData = {
        ...formData,
        start: startDateTime,
        end: endDateTime,
        promoter: JSON.parse(localStorage.getItem('user')).userId,
        url: imageURL
      };
      delete eventData.startDate;
      delete eventData.endDate;

      console.log(eventData);

      // Call the API function to update the event
      const resp = await updateEvent(eventId, eventData);

      setMessage('Event updated successfully.');
      setShowToast(true);
      setDisableButton(false);

      navigate(`/promoter/event/${resp.data.slug}`);
    } catch (error) {
      console.error('Error updating event:', error);
      setMessage('Error updating event.');
      setShowToast(true);
      setDisableButton(false);
    }
  };

  return (
    <EventEdit
      formData={formData}
      setFormData={setFormData}
      selectedFile={selectedFile}
      selectedName={selectedName}
      handleFileChange={handleFileChange}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      handleVenueSelected={handleVenueSelected}
      address={address}
      disableButton={disableButton}
      imageLoading={imageLoading}
      errors={errors}
      handleInputBlur={handleInputBlur}
      setShowToast={setShowToast}
      showToast={showToast}
      toastMessage={message}
      loading={loading}
      imageURL={imageURL}
    />
  );
}

export default EventEditContainer;
