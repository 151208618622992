const Ad5Component = ({ index }) => {
  const adIndex = Math.floor(index / 5) % 5; // Get the correct ad index

  // useEffect(() => {
  //   const adContainer = adRef.current;

  //   // Clear the container by removing all child nodes
  //   while (adContainer.firstChild) {
  //     adContainer.removeChild(adContainer.firstChild);
  //   }

  //   // Create a new script element
  //   const scriptElement = document.createElement('script');
  //   scriptElement.type = 'text/javascript';
  //   scriptElement.src = ads[adIndex].scriptUrl;
  //   scriptElement.async = true; // Ensures non-blocking load
  //   scriptElement.onload = () => console.log(`Ad ${ads[adIndex].id} loaded`); // Debugging for script load
  //   const timer = setTimeout(() => {
  //     adContainer.appendChild(scriptElement);
  //   }, 1000); // Adjust the delay as needed

  //   // Append the script element to the ad container
  //   return () => {
  //     clearTimeout(timer); // Clear the timeout
  //     if (adRef.current && adRef.current.contains(scriptElement)) {
  //       adRef.current.removeChild(scriptElement); // Cleanup script
  //     }
  //   };
  // }, [adIndex]); // Only re-run effect if adIndex changes

  return (
    <div id={`ad-${index}-${adIndex}`} className="ad-container row">
      <iframe
        width="1080"
        height="1080"
        src="https://g.adspeed.net/ad.php?do=html&aid=1110979&oid=29387&wd=1080&ht=1080&target=_blank"
        frameBorder="0"
        scrolling="no"
        allowTransparency="true"
        hspace="0"
        vspace="0"
        style={{ width: '100%', height: 'auto', aspectRatio: '1/1' }}
      >
      </iframe>
    </div>
  );
};

export default Ad5Component;
