import React, { useRef, useEffect, useState } from 'react';
import config from '../../../config/config';

const AddressAutocomplete = ({ value, onPlaceSelected }) => {
  const inputRef = useRef(null);
  const initializeAutocompleteRef = useRef(null);
  const MAP_API_KEY = config.GOOGLE_MAPS_API_KEY;
  const [loadingError, setLoadingError] = useState(false);

  useEffect(() => {
    let retries = 0;
    const MAX_RETRIES = 3;

    // Define initializeAutocomplete function
    window.initializeAutocomplete = () => {
      if (initializeAutocompleteRef.current) {
        initializeAutocompleteRef.current();
      }
    };

    initializeAutocompleteRef.current = () => {
      if (inputRef.current && window.google && window.google.maps && window.google.maps.places) {
        try {
          const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current);
          autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            onPlaceSelected(place);
            inputRef.current.value = place.name || '';
          });
          setLoadingError(false);
        } catch (error) {
          console.error("Error initializing autocomplete:", error);
          setLoadingError(true);
        }
      }
    };

    const loadGoogleMaps = () => {
      if (!window.google || !window.google.maps || !window.google.maps.places) {
        return new Promise((resolve, reject) => {
          const existingScript = document.querySelector('script[src*="maps.googleapis.com"]');
          if (existingScript) {
            // Remove existing script if any
            existingScript.remove();
          }

          const script = document.createElement('script');
          script.src = `https://maps.googleapis.com/maps/api/js?key=${MAP_API_KEY}&libraries=places&callback=initializeAutocomplete`;
          script.async = true;
          script.defer = true;

          script.onerror = () => {
            retries += 1;
            console.warn(`Retrying Google Maps API load... Attempt ${retries}`);
            if (retries <= MAX_RETRIES) {
              setTimeout(loadGoogleMaps, 1000); // Retry with a slight delay
            } else {
              reject(new Error('Failed to load Google Maps API after multiple attempts.'));
              setLoadingError(true);
            }
          };

          script.onload = () => resolve();
          document.head.appendChild(script);
        });
      } else {
        initializeAutocompleteRef.current();
        return Promise.resolve();
      }
    };

    loadGoogleMaps().catch(error => {
      console.error(error);
    });

    return () => {
      // Clean up the Google Maps script and listeners when component unmounts
      const googleScript = document.querySelector('script[src*="maps.googleapis.com"]');
      if (googleScript) googleScript.remove();
      delete window.initializeAutocomplete;
    };
  }, [onPlaceSelected, MAP_API_KEY]);

  return (
    <div>
      <input ref={inputRef} className="form-control" defaultValue={value} type="text" id="venueName" name="venueName" />
      {loadingError && <p className="text-danger">Failed to load location suggestions. Please try again later.</p>}
    </div>
  );
};

export default AddressAutocomplete;
