import React, { useState } from 'react';
import "./FlyerThumbnails.css";

const FlyerThumbnails = (props) => {
    const [selectedImage, setSelectedImage] = useState(null);

    const handleThumbnailClick = (imageUrl) => {
        setSelectedImage(imageUrl);
    };

    return (
        <div>
            <div className="thumbnail-container" style={{ display: 'flex', gap: '10px' }}>
                {props.images.map((image, index) => (
                    <button
                        type='button'
                        key={index}
                        onClick={() => handleThumbnailClick(image)}
                        style={{ cursor: 'pointer', padding: 0, border: 'none', background: 'none' }}
                    >
                        <img
                            src={image}
                            alt={`Thumbnail ${index + 1}`}
                            style={{ width: '120px', height: 'auto' }}
                        />
                    </button>
                ))}
            </div>

            {selectedImage && (
                <>
                    <div className="full-image-container" style={{ marginTop: '20px' }}>
                        <h3>Selected Image</h3>
                        <img
                            src={selectedImage}
                            alt="Full size"
                            style={{ width: '100%', height: 'auto' }}
                        />
                        <button
                            type="button"
                            className="btn"
                            id="flyer-upload-button"
                            style={{ marginTop: '20px' }}
                            onClick={() => props.handleFileChange(selectedImage)}
                        >
                            Use this image as flyer
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default FlyerThumbnails;